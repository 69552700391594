// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-agenda-js": () => import("../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-cursos-js": () => import("../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-facebook-js": () => import("../src/pages/facebook.js" /* webpackChunkName: "component---src-pages-facebook-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-js": () => import("../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-israel-js": () => import("../src/pages/israel.js" /* webpackChunkName: "component---src-pages-israel-js" */),
  "component---src-pages-mantenedores-js": () => import("../src/pages/mantenedores.js" /* webpackChunkName: "component---src-pages-mantenedores-js" */),
  "component---src-pages-mp-3-js": () => import("../src/pages/mp3.js" /* webpackChunkName: "component---src-pages-mp-3-js" */),
  "component---src-pages-spotify-js": () => import("../src/pages/spotify.js" /* webpackChunkName: "component---src-pages-spotify-js" */),
  "component---src-pages-twitter-js": () => import("../src/pages/twitter.js" /* webpackChunkName: "component---src-pages-twitter-js" */),
  "component---src-pages-youtube-js": () => import("../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */)
}

